@import url("https://fonts.google.com");

.App {
  text-align: center;
  margin: 0% !important;
  padding: 0% !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem !important;
  margin-top: 15px !important;
  color: #00A658 !important;
  /* font-weight: 400 !important; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.login-wrapper {
  background: url("../../public/background3.jpg") no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #801245cb !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.main {
  /* height:100vh; */
  position: absolute;
  display: flex;
}

div.chartjs-tooltip {
  position: absolute;
  z-index: 1021;
  padding: 0.25rem 0.5rem;
  color: #fff;
  font-size: 14px;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: all 0.15s ease;
  transform: translate(-50%);
  border-radius: 0.25rem;
}

.btn-group {
  box-shadow: none !important;
}
/* .modal {
  width: 751px !important;
} */
.modal-content {
  width: 140% !important;
}
.modal-dialog {
  max-width: 751px !important;
}
.modal-holiday {
  width: 310px !important;
}
.table th {
  font-weight: 602 !important;
}
.table-success {
  background-color: #212529;
}
.roaster-text {
  margin-left: 280px !important;
}

.tableRow1 {
  background: #1564BF;
}
.tableRow2 {
  background-color: #ced4da;
}
.user {
  padding: 4px;
  font-family: "Roboto";
  font-size: 16px;
  margin-left: 4px;
  margin-top: 8px;
  font-weight: bold;
}
.user-text {
  font-family: "Roboto";
  font-size: 16px;
  margin-left: 5px;
  margin-top: 31px;
}
.user-icon {
  font-family: "Roboto";
  font-size: 17px;
  margin-left: 348px;
  margin-top: -3px;
}
.admin-icon {
  font-family: "Roboto";
  font-size: 15px;
  margin: auto;
}
.userStatus{
  margin-left: 40px;
}
.admin {
  font-family: "Roboto";
  font-size: 16px;
  margin-left: 5px;
  margin-top: 8px;
  font-weight: bold;
}
.admin-text {
  font-family: "Roboto";
  font-size: 16px;
  margin-left: 3px;
  margin-top: 8px;
}

.autocomplete input {
  width: 90%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.autocomplete input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.autocomplete > div > div {
  z-index: 2;
}

li a:hover {
  color: white !important;
  border-radius: 10px;
  border-bottom: 6px solid #993366;
  font-weight: bold;
}
.navbar li a:hover, .pro-menu-item a:hover {
  color: white !important;
  border-radius: 10px;
  border-bottom: 6px solid white;
  font-weight: bold;
}
.card-body a:hover {
  color: white !important;
}
.container .form-check-input[type="checkbox"]:checked:after {
  display: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 10px !important;
}
.toast {
  width: 250px !important;
}

div#toast-container {
  margin-top: 1rem;
  left: revert;
  right: 12px;
}
#toast-container.toast-top-full-width > .toast {
  max-width: 300px;
  float: right;
}

#toast-container > .toast-info {
  background-color: #88aaff;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#toast-container > .toast-info:hover {
  background-color: #0088ff;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

#toast-container > .toast-warning {
  background-color: #1a1d20;
  color: #1564BF;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#toast-container > .toast-warning:hover {
  background-color: #212529;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

#toast-container > .toast-success {
  background-color: #993366;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#toast-container > .toast-success:hover {
  background-color: #993366;
  color: white;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

#toast-container > .toast-error {
  background-color: #ff6767;
  color: white;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#toast-container > .toast-error:hover {
  background-color: #ff3434;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.assign:nth-child(0) {
  color: red;
}

.payroll.hover {
  cursor: pointer;
}
.payroll.hover:hover {
  color: red;
}
.form-check-label {
  color: black;
}
.form-check-input:before {
  content: none !important;
}
.btn-group .btn {
  margin: 0px;
}
.btn {
  margin: 15px;
}
.Rform-control1 {
  margin-left: 41px;
}
.Rbtn-success1 {
  margin-left: 53px;
}
.Rbtn-success2 {
  margin-left: -89px;
}

.Pbtn-success1 {
  margin-left: -31px;
}
.Pbtn-success2 {
  margin-left: -176px;
}

.sc-iBkjds {
  padding: 5px !important;
}
.jqNiPQ {
  padding: 0px !important;
}
#basic_tab-tabpane-holiday .sc-evZas,
.workingTable .sc-evZas {
  min-height: 5vh !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ncard > div > div {
  border: 1px solid #023f75;
  padding-top: "5px" !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4) !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ncard > div > div > div {
  text-align: left;
  padding-left: 15px;
}

.filter-dropdown {
  border-color: #198754 !important;
  color: #198754 !important;
  margin-top: 15px;
}

.main-row{
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-orange{
  color: black !important;
  border:#00A658 1px solid !important;
  font-weight: bolder !important;
}
.btn-orange:hover{
  background-color: #00A658 !important;
  color: white !important;
  border: #00A658 1px solid !important;
}

.error-input{
  border: 1px solid red !important;
}
.btn1{
  color: #198754;
}
.btn1:hover {
   color: #ffffff 
}

.statusBadge{
  margin-left: 1rem !important;
  font-size: 16px !important;
}

.btn-main{
  border: 1px solid #00A658 !important;
  background-color: #00A658 !important;
  color: white !important;
}
.btn-main:hover{
  background-color: #00A658 !important;
  font-weight: bold !important;
}
.form-check-input.is-valid:checked{
  background-color: #212529 !important;
  border-color: #993366 !important;
}

.form-check-input.is-valid{
  border-color: #D16587 !important;
}

.payroll-tooltip{
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 322px !important;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #1a1d20 !important;
}

.form-check-input.is-valid~.form-check-label{
  color: #1a1d20 !important;
}

.pnl-row{
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-body{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.password-body .item{
  width: 200px;
}

.setting-body{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.setting-body label{
  width: 70% !important;
}
.setting-body .item{
  width: 100px;
}
.setting-body .item-date{
  width: 200px;
}
.card-title{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #993366;
  font-weight: bold;
}
.form-switch .form-check-input:checked[type=checkbox] {
  background-color: #993366 !important;
}

.color-main{
  color: #D16587!important;
}
.bold{
  font-weight: bold !important;
}
.color-red{
  color:  red !important;
}
.color-green{
  color: #198754 !important;
}

@media (min-width <= 767px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    min-width: 95% !important; 
    /* padding-left: 50px !important;  */ 
}
}
@media (min-width > 767px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    min-width: 95% !important;
    width: 95% !important ;
}
}

.login-text{
  background-color: aliceblue !important;
}

.rdt_TableHead{
  border: 1px solid #00A658 !important;
  border-top-left-radius: 10px !important;
  color: white !important;
  border-top-right-radius: 10px !important;
}
.rdt_TableHead>div{
  background-color:#00A658 !important;
  border: 1px solid #00A658 !important;
  color: white !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  }
  .react-date-picker__wrapper{
    padding: 3px;
    border-radius: 5px;
  }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner{
    background-color:#993366 !important;
  }
  .goBWWW, .fljiHE, .sc-dPyBCJ.byOzWr.rdt_TableHeadRow{
    background-color: #993366 !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-segi59, .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-18nc3u2 .MuiOutlinedInput-root{
    border-radius: 35px !important;
    padding: 0px 9px !important;
  }
  .search-term input,   .search-term input{
    padding: 9px 15px !important;
    border-radius: 15px
  }
  .css-1bp1ao6, .css-xa1gac, .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root, .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    border-radius: 15px !important;
  }

  .nurse-selection {
    width: 100%;
    border: 1px solid transparent !important;
    border-radius: 15% !important;
  }
  .css-t3ipsp-control:hover{
    border-color: transparent !important;
  }
  .css-t3ipsp-control{
    border-color: transparent !important;
  }
  .nurse-selection svg, .css-1u9des2-indicatorSeparator{
    display: none !important;
  }