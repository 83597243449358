#sidebar {
  position: absolute;
}

#sidebar .closemenu {
  color: #fff;
  position: fixed;
  left: 20;
  margin-left: 30px;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 30px;
  top: 15px;
  cursor: pointer;
}

#sidebar .pro-sidebar {
  width: 250px;
  min-width: 260px;
  height: 100vh;
  position: fixed;
}


#sidebar .pro-sidebar.collapsed {
  width: 90px;
  min-width: 90px;
}
/*   */
#sidebar .pro-sidebar-inner{
  background-color: #00A658 !important;
  box-shadow: 0.5px 0.866px 2px 3px #00000026;
}
.pro-inner-list-item {
  background-color: #993366 !important;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: auto;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  /* margin: 0px; */
  /* padding-bottom: 3px; */
  font-weight: bold;
  font-size: 13px;
}
#sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #222527;
  color: #993366;
  border-radius: 2px;
}

#sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: white;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout .active {
  /* background-color: #234E70; */
  background-color: inherit;
  color: white;
  border-bottom: 4px solid white;
  padding: 5px;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout a {
  color: #212529;
  font-size: 14px;
}
#sidebar .logo {
  padding: 20px;
}

#sidebar_notification .closemenu {
  color: #fff;
  position: fixed;
  right: 0px;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 30px;
  top: 0px;
  cursor: pointer;
}
#sidebar_notification .pro-sidebar {
  width: 250px;
  min-width: 270px;
  height: 100vh;
  right:0;
  position: fixed;
}
#sidebar_notification .pro-sidebar.collapsed {
  width: 0px;
  min-width: 0px;
}

#sidebar_notification .pro-sidebar-inner {
  background-color: #00A658;
  box-shadow: 0.5px 0.866px 2px 2px #00000026;
}
#sidebar_notification .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: auto;
}
#sidebar_notification .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 12px;
  padding: 0 5px;
  color: #000;
  font-weight: bold;
}
#sidebar_notification .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 2px;
}
#sidebar_notification .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 5px 0px;
  font-weight: bold;
}
#sidebar_notification
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #eeeeee;
  color: #000;
  border-radius: 3px;
}
#sidebar_notification
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
#sidebar_notification .btn-close{
  display: none;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: auto;
  }

}
@media only screen and (max-width: 720px) {
  .closemenu {
    display: block !important;
  }

}
@media only screen and (max-width: 720px) {
.navbar-brand{
  margin-left: 25px !important;
}
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item{
  transition: visibility 0.5s ease-in-out !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item{
  padding: 0px 30px 0px 15px !important;
}
.pro-item-content{
  color: #212529 !important;
}
.pro-sub-menu .pro-inner-item>span.pro-item-content:hover {
  color: white !important;
}
.pro-sub-menu .pro-inner-item>span.pro-item-content{
  padding: 0px 9px !important;
}
/* .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item{
  padding-left: 35px !important;
} */
.pro-inner-list-item.popper-element{
  padding-left: 0px !important;
  /* transform: translate(85px, 303px) !important; */
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-inner-list-item.popper-element{
  padding-left: 0px !important;
}
.filter-term .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
  padding: 0px 15px !important;
  border-radius: 15px;
}
.filter-term .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root .css-i4bv87-MuiSvgIcon-root{
  display: none !important;
}
#checkboxes-tags-demo .MuiAutocomplete-root.MuiAutocomplete-fullWidth.MuiAutocomplete-hasPopupIcon.css-1kkal6p-MuiAutocomplete-root{
  width: 100%;
}
.vehicle-date-picker .react-datepicker-wrapper{
  width: 100%;
}
.vehicle-date-picker input{
width: 100%;
padding: 12px;
}

.fxIqEl{
  padding: 0px !important;
}

div#mui-component-select-vehicleTypeFilter {
  padding: 5px 15px !important;
}
.input-filter, .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.filter-term.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  border-radius: 15px !important;
}

.input-filter .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 5px 15px !important;
  border-radius: 15px !important;
}
.remarks-btn{
  background-color: transparent !important;
}
.pr-file-upload span.MuiFileInput-placeholder{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0 !important;
}
.pr-file-upload{
  padding: 0px !important;
}
.pr-file-upload input{
  border: 0px !important;
  outline: none !important;
  padding: 0px !important;
}
.pr-file-upload fieldset{
  border: 0px !important;
}
@keyframes borderAnimation {
  0% {
    border-image: linear-gradient(
      45deg,
      #ff3cac,
      #784ba0,
      #2b86c5,
      #1cb5e0,
      #ff3cac
    );
    border-image-slice: 1;
  }
  25% {
    border-image: linear-gradient(
      45deg,
      #784ba0,
      #2b86c5,
      #1cb5e0,
      #ff3cac,
      #784ba0
    );
    border-image-slice: 1;
  }
  50% {
    border-image: linear-gradient(
      45deg,
      #2b86c5,
      #1cb5e0,
      #ff3cac,
      #784ba0,
      #2b86c5
    );
    border-image-slice: 1;
  }
  75% {
    border-image: linear-gradient(
      45deg,
      #1cb5e0,
      #ff3cac,
      #784ba0,
      #2b86c5,
      #1cb5e0
    );
    border-image-slice: 1;
  }
  100% {
    border-image: linear-gradient(
      45deg,
      #ff3cac,
      #784ba0,
      #2b86c5,
      #1cb5e0,
      #ff3cac
    );
    border-image-slice: 1;
  }
}

.animate-border {
  animation: borderAnimation 4s linear infinite;
}


.navbar-bg{
  background-color: white !important;
}
.CareCal-Checkboxes{
  width: 0.8em !important;
  height: 0.8em !important;
  border-radius: 1.25em !important;
}
.patient_Admit_Autocomplete input{
  height: -webkit-fill-available !important;
  width: 100% !important;
}