.bg-dark-n{
    /* background: rgb(2,0,36); */
    background: linear-gradient(90deg, #D16587 0%, #993366 52%, #D16587 100%);
}

.login-btn{
    width: 100%;
    background: radial-gradient(circle at 10% 20%, rgb(69, 86, 102) 0%, rgb(34, 34, 34) 90%);
    /* border: black 1px solid !important; */
    box-shadow: 8px 12px 30px black !important;
}

.login-btn:hover{
   
    background: radial-gradient(circle at 40% 20%, rgb(69, 86, 102) 0%, rgb(34, 34, 34) 90%);
   
    box-shadow: 8px 12px 20px black !important;
}